import React from 'react';
import { Link } from 'gatsby';
import StandardOutboundLink from '../standard-outbound-link';

const ConditionalsIntroduction = () => (
  <div>
    <StandardOutboundLink href="https://docs.google.com/presentation/d/1YSWxX1VrUUcJnKdCrF_MpMwsp-JZAeqou8uiVc4S16w/edit?usp=sharing">
      Link to Slides
    </StandardOutboundLink>
    <h3>Learning Objectives</h3>
    <ul>
      <li>To understand “if” and “else” statements and what they are used for</li>
      <li>To understand conditional operators</li>
    </ul>
    <h3>Important Concepts/Vocabulary</h3>
    <ul>
      <li>Conditional: Code that decides which code to run</li>
      <li>Operators: Mathematical expressions/characters that represent some type of action</li>
      <ul>
        <li>&lt;</li>
        <li>&gt;</li>
        <li>=</li>
        <li>and</li>
        <li>or</li>
        <li>not</li>
      </ul>
    </ul>
    <h3>Learning Activities/Games</h3>
    <h4>
      Write a &quot;choose your own adventure&quot; story
    </h4>
    <p>Materials: 16 note cards per coder</p>
    <p>
      Write a number on each note card. On note card #1, write a question.
      Depending on how the reader answers, they should be sent to a new note card (like #2 or #3).
      Then the reader should see another question. Repeat!
    </p>
    <h4>If-Else Direction Game</h4>
    <p>
      One person is the coder who give if (and else) statements,
      and everyone are the computer who are interpreting the instructions and carrying them out.
    </p>
    <p>
      Ex: If I touch my left elbow then take a step to the left (else take a step to the right),
      or if I touch my right elbow then take a step to the right.
    </p>
    <h3>Code Championship Challenge</h3>
    <p>
      <Link to="/code/">Skyward Cannons</Link>
    </p>
  </div>
);

export default ConditionalsIntroduction;
