import React from 'react';
import { Link } from 'gatsby';
import StandardOutboundLink from '../standard-outbound-link';

const LoopsIntroduction = () => (
  <div>
    <StandardOutboundLink href="https://docs.google.com/presentation/d/1AG7ls-2eHLRFFgFh-zNGJTAgbr_UIr6EmVRNrFsl5LM/edit?usp=sharing">
      Link to Slides
    </StandardOutboundLink>
    <h3>Learning Objectives</h3>
    <ul>
      <li>To understand what loops can provide while coding</li>
      <li>To understand the three different loops that Scratch has</li>
    </ul>
    <h3>Important Concepts/Vocabulary</h3>
    <ul>
      <li>Scratch: The coding language that Code Championship uses</li>
      <li>Loop: A way to repeat code multiple time</li>
      <li>Repeat # : This loop will repeat as many times based on the # the programmer gives it</li>
      <li>Repeat Until: This loop will repeat until a certain condition is met</li>
      <li>Forever: This loop will continue to repeat forever</li>
    </ul>
    <h3>Learning Activities/Games</h3>
    <ul>
      <li>Programmer Says 2.0 (similar to Simon Says but with loops)</li>
    </ul>
    <h3>Code Championship Challenge</h3>
    <p>Focus on using fewer blocks</p>
    <p>
      <Link to="/code/">Rabbit Race</Link>
    </p>
  </div>
);

export default LoopsIntroduction;
