import React from 'react';
import { Link } from 'gatsby';
import StandardOutboundLink from '../standard-outbound-link';

const SensingIntroduction = () => (
  <div>
    <StandardOutboundLink href="https://docs.google.com/presentation/d/1hf1tbV-yH2iOLi5f7MdMoZzROr4bSXkDBTBxBZSgDbE/edit?usp=sharing">
      Link to Slides
    </StandardOutboundLink>
    <h3>Learning Objectives</h3>
    <ul>
      <li>To understand sensing and what it’s used for</li>
      <li>To incorporate sensing with conditionals</li>
    </ul>
    <h3>Important Concepts/Vocabulary</h3>
    <ul>
      <li>Sensing: Identifying an object or space relative to a given position</li>
      <li>Distance to … (Distance to Target 2)</li>
      <li>… of … (x position of Target 1)</li>
    </ul>
    <h3>Learning Activities/Games</h3>
    <h4>Heads Up, Seven Up</h4>
    <p>
      Seven students stand in front of the class.
      The rest of the students put their heads on their desks.
      The seven then move about and each touches a student.
      Once touched, a student sticks his or her thumb up.
      Then the seven say “heads up seven up!” The students
      who were touched then get a chance to guess which of the seven touched each of them.
      If they guessed right, they get to change places and be one of the students in the front.
    </p>
    <h3>Code Championship Challenge</h3>
    <p>At this point, a coder should be able to compete in any challenge.</p>
    <p>
      <Link to="/code/">Battle Cannons</Link>
      {' and '}
      <Link to="/code/">Jewel Drill</Link>
    </p>
  </div>
);

export default SensingIntroduction;
