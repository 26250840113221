import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';

import PresentationSection from '../components/presentation-section';
import DocumentSection from '../components/document-section';
import ScratchIntroduction from '../components/lessons/scratch-introduction';
import LoopsIntroduction from '../components/lessons/loops-introduction';
import ConditionalsIntroduction from '../components/lessons/conditionals-introduction';
import SensingIntroduction from '../components/lessons/sensing-introduction';
import DebuggingIntroduction from '../components/lessons/debugging-introduction';
import VariablesAndFunctionsIntroduction from '../components/lessons/variables-and-functions-introduction';
import SEO from '../components/seo';

const CurriculumPage = () => (
  <Layout>
    <SEO
      title="Coding Curriculum for Teachers with Competitive Students | Make coding exciting with Code Championship"
      description="A full week of curriculum to teach your students how to code. Students learn through head to head competition and excitement while writing code!"
      keywords={['curriculum', 'teaching', 'teacher', 'student']}
    />
    <h1>Curriculum</h1>
    <div className="stripe-hr thin" />
    <h2>Disclaimer</h2>
    <p>
      This curriculum is a work in process.
      We&apos;re a very small company and only a handful of very passionate instructors
      are using this curriculum, so it is not as well supported as we would like it to be.
      If you have any questions or feedback,
      please reach out to me directly at luke@codechampionship.com
    </p>
    <p>
      - Luke Schlangen, Co-Founder of Code Championship
    </p>
    <h2>Timeline</h2>
    <p>Four 3-hour days for a total of 12 hours of curriculum</p>
    <h2>Student Objectives</h2>
    <ul>
      <li>To be excited about writing code</li>
      <li>To be able to create a bot using Scratch</li>
      <li>To be able to compete head-to-head against other students using code</li>
    </ul>
    <h2>Terminology</h2>
    <ul>
      <li>Students → &quot;Coders&quot; or &quot;Players&quot;</li>
      <li>Instructors → &quot;Coaches&quot; or &quot;Referees&quot;</li>
      <li>Base projects to start from → &quot;Games&quot; or &quot;Challenges&quot;</li>
      <li>Projects built by students → &quot;Bots&quot;</li>
    </ul>
    <h2>Daily Structure</h2>
    <ol>
      <li>Name game</li>
      <li>Introduce the challenge for the day</li>
      <li>One hour to build/test a bot</li>
      <li>Break for stretching/snack</li>
      <li>Introduce a new strategy/computer science principle</li>
      <li>One hour to build/test a bot</li>
      <li>Break for stretching/snack</li>
      <li>Pool Play</li>
      <li>Tournament Play</li>
      <li>Wind Down/Sportsmanship Time</li>
      <li>Show Advanced Code Examples</li>
    </ol>
    <h2>Important Links</h2>
    <Link to="/tournament/" target="_blank">
      Tournament Page
    </Link>
    <h2>Preparation Checklist</h2>
    <ul>
      <li>
        Power up computers immediately upon arrival. Some will have a long startup process
        (Leave a couple extra computers logged on if another crashes during the class).
      </li>
      <li>
        Find/Read Emergency Procedures for the classroom or ask the community
        ed director about emergency procedures
      </li>
      <li>
        Ask what the expectations of instructors are for their host
        (they are all different).
      </li>
      <li>
        Ask for the Check-In Procedure: Do you pick up the students,
        or do they get dropped off in the classroom?
      </li>
      <li>Ask the community ed director if and where they can go outside for break</li>
      <li>Find where the nearest bathrooms and drinking fountains are</li>
      <li>Verify the pick up procedure at the end of the day</li>
      <li>Do you wait for the kids or do the school officials?</li>
      <li>Do you bring the kids somewhere or keep them in the classroom?</li>
      <li>
        When the parents drop the children off,
        introduce yourself and thank them for being in the class.
        Explain the pickup process and encourage them to bring a snack and earbuds the next day
        (if the school where you are teaching does not provide them.)
      </li>
      <li>Take a bathroom break before class starts</li>
    </ul>
    <h2>Classroom Procedures</h2>
    <p>
      Rules we would like them to guess themselves
      (if not, lead them that direction until they can guess)
    </p>
    <ul>
      <li>Be respectful and encouraging - help each other out</li>
      <li>Use your indoor voices, this is a computer lab</li>
      <li>If you have a question, raise your hand</li>
      <li>Don’t touch others computers (we want them to learn on their own!)</li>
      <li>Stay on the websites we are working on</li>
      <li>At the end of class, clean your space</li>
      <li>
        Remember you are using school property,
        so you are responsible for the property you use for the week
      </li>
      <li>
        Explain the class structure: Break halfway
        (Snacks, Bathroom, Outside?) and Hands-on Activities
      </li>
    </ul>
    <p>
      Create a penalty box where coders will go if they violate a rule.
      Rule violations (fouls) will result in 2-minute penalties.
      Everything should be framed in the context of the game.
      The referee/coach will have the discretion to assign penalty times for
      flagrant fouls (fouls beyond those described in the rules).
    </p>
    <h2>Hallway Procedures</h2>
    <p>Important! Be sure to set expectations BEFORE you leave the room.</p>
    <ul>
      <li>We use indoor voices in the hall</li>
      <li>We walk and keep our hands to ourselves</li>
      <li>
        We walk together. No one runs ahead in front of the coach.
        The coach is the first one in the line.
      </li>
      <li>We will take bathroom breaks</li>
    </ul>
    <hr className="stripe-hr" />
    <h2>Lesson 1 - Introduction to Scratch</h2>
    <ScratchIntroduction />
    <PresentationSection
      src="https://docs.google.com/presentation/d/e/2PACX-1vRwRFd7HFperWHIns9kJUGUj7ra9a53dyivA-ZrrnCMOn2CJMnac4wZonRFzsR5djmmUkY32WNMR-y7/embed?start=false&loop=false&delayms=3000"
      title="Lesson 1 Slides"
    />
    <hr className="stripe-hr" />
    <h2>Lesson 2 - Loops Introduction</h2>
    <LoopsIntroduction />
    <PresentationSection
      src="https://docs.google.com/presentation/d/e/2PACX-1vSDEnQa-LtcOK3SnaDjP80ZlhX6pgQPsxxyz_pOruLcPYDHyrdDDdSffyjWpmsgBTgZSPlLRgxRgW66/embed?start=false&loop=false&delayms=3000"
      title="Lesson 2 Slides"
    />
    <hr className="stripe-hr" />
    <h2>Lesson 3 - Conditionals Introduction</h2>
    <ConditionalsIntroduction />
    <PresentationSection
      src="https://docs.google.com/presentation/d/e/2PACX-1vRVrM7CrNtCiq6kAClTLTjYmNr1fJJ09UhHZQf9qdzf6q0nsrQYneQX7EAGmX4P1w9Vv6aU-886h-6L/embed?start=false&loop=false&delayms=3000"
      title="Lesson 3 Slides"
    />
    <hr className="stripe-hr" />
    <h2>Lesson 4 - Sensing Introduction</h2>
    <SensingIntroduction />
    <PresentationSection
      src="https://docs.google.com/presentation/d/e/2PACX-1vRg8NnNx0GsnnmhmhDnYg8V_krNMLYvx0QHBbnv-vXLS82LMkWiWuXshetbSsvq7tZ9xOXCr8Lqb4Ly/embed?start=false&loop=false&delayms=3000"
      title="Lesson 4 Slides"
    />
    <hr className="stripe-hr" />
    <h2>Lesson 5 - Debugging Introduction</h2>
    <DebuggingIntroduction />
    <PresentationSection
      src="https://docs.google.com/presentation/d/e/2PACX-1vTAb4Di9Q6GOA3woR-iaszbgy0bYxx12HRtGisVEaDXmawi2AFvPYhmdJrkU83LALpUcHYVAtN9GnLl/embed?start=false&loop=false&delayms=3000"
      title="Lesson 5 Slides"
    />
    <hr className="stripe-hr" />
    <h2>Lesson 6 - Variables and Functions Introduction</h2>
    <VariablesAndFunctionsIntroduction />
    <DocumentSection
      src="https://docs.google.com/document/d/e/2PACX-1vTc6c23TO4chzWGOK_gwIVNZ0ciXFHNt4lYGfCuYU_3w2Snm4sX23IJt-wZfEKZ3WBfuv7XsJgI26Ep/pub?embedded=true"
      title="Lesson 6+7 Plan"
    />
    <PresentationSection
      src="https://docs.google.com/presentation/d/e/2PACX-1vTM1DD8sxpfhBrjv9bZwWFSTeBAJBTT18KlZINIt6eGsZchZjcUQ2zcYDbiX5gYOo8m2QP_5wx_6zfL/embed?start=false&loop=false&delayms=3000"
      title="Lesson 6 Slides"
    />
    <h3>Scratch Function Block Review</h3>
    <h4>My Blocks</h4>
    <li>Move X: moves bot in the direction indicated.</li>
    <h4>Control</h4>
    <ul>
      <li>Wait X seconds: The program will wait that amount.</li>
      <li>Repeat X: Will repeat any code inside X amount of times.</li>
      <li>Forever: Repeats code inside forever.</li>
      <li>If X then: If X happens then the code inside will run.</li>
      <li>
        If X then, else: If X happens then code inside will run,
        otherwise else will run
      </li>
      <li>Wait until X: Program will stop until X happens.</li>
      <li>Repeat until X: Code will repeat until X happens.</li>
    </ul>
    <h4>Motion</h4>
    <ul>
      <li>x position: returns the value of the horizontal position of the bot </li>
      <li>y position: returns the value of the vertical position of the bot </li>
    </ul>
    <h4>Sensing</h4>
    <ul>
      <li>touching X:determines whether or not the bot is touching a certain object X</li>
      <li>
        distance to X:returns a value that is the numerical
        distance of the bot from a certain object X
      </li>
    </ul>
    <h4>Operators</h4>
    <ul>
      <li><h5>Arithmetic Operators</h5></li>
      <ul>
        <li>
          X + Y: performs addition between two numbers X and Y,
          and returns the sum between the two
        </li>
        <li>
          X - Y: performs subtraction between two numbers X and Y,
          and returns the difference between the two
        </li>
        <li>
          X * Y: performs multiplication between two numbers X and Y,
          and returns the product between the two
        </li>
        <li>
          X / Y: performs division between two numbers X and Y,
          and returns the quotient between the two
        </li>
      </ul>
      <li>pick random X to Y: Returns a random number between a number X and a number Y</li>
      <li><h5>Comparison Operators </h5></li>
      <ul>
        <li> X &gt; Y: determines whether or not a value X is greater than a value Y</li>
        <li> X &lt; Y: determines whether or not a value X is less than a value Y</li>
        <li> X = Y: determines whether or not a value X is equal to a value Y </li>
      </ul>
      <li><h5>Logical Operators </h5></li>
      <ul>
        <li>
          X and Y: determines whether or not two conditions,
          X and Y, have both occurred
        </li>
        <li>
          X or Y: determines whether or not one, or both,
          of two conditions, X or Y, has occurred
        </li>
        <li> not X: reverses the logical determination of the condition X</li>
      </ul>
    </ul>
  </Layout>
);

export default CurriculumPage;
