import React from 'react';
import StandardOutboundLink from '../standard-outbound-link';

const VariablesAndFunctionsIntroduction = () => (
  <div>
    <StandardOutboundLink href="https://docs.google.com/presentation/d/16H7ub3OJbBaTNsw2yOOG1OtZAn1L8ey_bP3AHv5l-XU/edit?usp=sharing">
      Link to Slides
    </StandardOutboundLink>
    <h3>Learning Objectives</h3>
    <ul>
      <li>To understand what is a variable</li>
      <li>To understand what is a function</li>
    </ul>
    <h3>Important Concepts/Vocabulary</h3>
    <ul>
      <li>Variable: Is a placeholder for a piece of information that can change</li>
      <li>Function: A reusable bit of code</li>
    </ul>
    <h3>Learning Activities/Games</h3>
    <h4>Variables:</h4>
    <h5>Mad Libs (find some online)</h5>
    <p>Assigning something to the given word</p>
    <ul>
      <li>Ex: Noun 1 = code</li>
      <li>Ex: Verb 2 = running</li>
    </ul>
    <h4>Functions:</h4>
    <h5>What&apos;s my function</h5>
    <StandardOutboundLink href="https://docs.google.com/presentation/d/1M4j3cXxkWeyiuzY2eF9pem6IlF7Q_NvhdAhnBTUnKys/edit?usp=sharing">
      Activity Slides
    </StandardOutboundLink>
    <p>
      Break the coders into groups and give them a job description.
      The coders will then have to come up with a way for that worker to complete their tasks.
    </p>
    <p>
      Job Descriptions:
    </p>
    <ul>
      <li>Sandwich Decorator</li>
      <li>Smoothie/Shake Barista</li>
      <li>Car Cleaner/Washer</li>
      <li>Salad Decorator</li>
      <li>Packer for a Camping Trip</li>
    </ul>
    <h3>Code Championship Challenge</h3>
    <p>
      At this point, a coder should be able to compete in any challenge.
      Use functions to reduce the block count for their bots.
    </p>
  </div>
);

export default VariablesAndFunctionsIntroduction;
