import React from 'react';
import { Link } from 'gatsby';
import StandardOutboundLink from '../standard-outbound-link';

const ScratchIntroduction = () => (
  <div>
    <StandardOutboundLink href="https://docs.google.com/presentation/d/17Q9mC0LKQb6Yo6Z7NtWYMA8_JNm3IGSoPcvy7TcFhZo/edit?usp=sharing">
      Link to Slides
    </StandardOutboundLink>
    <h3>Learning Objectives</h3>
    <ul>
      <li>To understand fully understand what is coding</li>
      <li>To understand Scratch (and other coding languages) and what it provides</li>
      <li>To understand Scratch “My Blocks” (directional movement)</li>
    </ul>
    <h3>Important Concepts/Vocabulary</h3>
    <ul>
      <li>Code: Instructions for a program</li>
      <li>Coding: Is a list of instructions you give to a computer that it follows.</li>
      <li>Coder: An individual who writes code</li>
      <li>Scratch: Is a block-based visual programming language</li>
      <li>Directionals: Movement in any direction (example: left, right, up and down)</li>
    </ul>
    <h3>Learning Activities/Games</h3>
    <h4>Computer Instructions</h4>
    <ul>
      <li>Programmer Says (Like Simon Says)</li>
      <li>Red Light, Green Light</li>
    </ul>
    <h4>Directionals</h4>
    <h5>The Great Escape - Group Game</h5>
    <p>
      Set up an obstacle course with chairs or other objects in the room.
      A player starts at the other side of the room, while the others
      are giving the player directions
      (move right, move left, move forward, move back) to escape the obstacle course
    </p>
    <h5>Mr Roboto - Group Game</h5>
    <p>
      1 player are the hands/body (does all the motion),
      1 player is the eyes (can only state what they see),
      and 1 player is the brain (process what they see,
      and tell the hands/body what to do).
    </p>
    <h5>Maze Runner</h5>
    <p>Materials: 64 sheets of paper, or masking tape, to make an 8x8 grid</p>
    <p>
      Players have to walk on the paper and hope that they are going the right way,
      one at a time. If the player goes the wrong way, it will be mentioned, and the
      player will have to retrace their steps and start over, and have another person go.
      The goal of the game is for the whole team to make it out of the maze.
    </p>
    <h3>Code Championship Challenge</h3>
    <p>
      <Link to="/code/">Rabbit Race</Link>
    </p>
  </div>
);

export default ScratchIntroduction;
