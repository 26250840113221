import React from 'react';
import PropTypes from 'prop-types';

const PresentationSection = ({ src, title }) => (
  <iframe
    title={title}
    src={src}
    width="700"
    height="400"
    frameBorder="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  />
);

PresentationSection.propTypes = {
  src: PropTypes.string,
  title: PropTypes.string,
};

PresentationSection.defaultProps = {
  src: '',
  title: 'null',
};

export default PresentationSection;
