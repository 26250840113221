import React from 'react';
import StandardOutboundLink from '../standard-outbound-link';

const DebuggingIntroduction = () => (
  <div>
    <StandardOutboundLink href="https://docs.google.com/presentation/d/18-t6veBmYzXiQqE3NpD8qrIn13NepkfC10qrFy0jyxs/edit?usp=sharing">
      Link to Slides
    </StandardOutboundLink>
    <h3>Learning Objectives</h3>
    <ul>
      <li>To understand debugging and what it’s used for</li>
      <li>How to debug code</li>
    </ul>
    <h3>Important Concepts/Vocabulary</h3>
    <ul>
      <li>Debugging: The process of identifying and removing errors from code</li>
      <li>Error: Is a bug in a program to cause it to operate incorrectly</li>
    </ul>
    <h3>Learning Activities/Games</h3>
    <h4>The Power of Debugging</h4>
    <StandardOutboundLink href="https://docs.google.com/presentation/d/1XB1vH-LOHB2M1k_TMjNcFFSQcrKB-pPPoa7tRV1VK4k/edit?usp=sharing">
      Activity Slides
    </StandardOutboundLink>
    <h3>Code Championship Challenge</h3>
    <p>At this point, a coder should be able to compete in any challenge.</p>
  </div>
);

export default DebuggingIntroduction;
