import React from 'react';
import PropTypes from 'prop-types';

const DocumentSection = ({ src, title }) => (
  <iframe
    title={title}
    src={src}
    width="700"
    height="900"
    frameBorder="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  />
);

DocumentSection.propTypes = {
  src: PropTypes.string,
  title: PropTypes.string,
};

DocumentSection.defaultProps = {
  src: '',
  title: 'null',
};

export default DocumentSection;
